.section {
  padding: rem-calc(64 0);

  &--contrast {
    background: $primary-100;
  }

  &--dark {
    background: $primary-400;
  }
}