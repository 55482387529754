.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.feature-image {
  width: 100%;

  @include breakpoint(medium, max) {
    margin-bottom: rem-calc(32);
  }
}

.wrapper {
  margin-top: rem-calc(160);
}