.tns-item {
  transition: .4s all;
}

.tns-item, .tns-slider .tns-slide-active + .tns-slide-active + .tns-slide-active {
  opacity: 0.2;
}

.tns-slider .tns-slide-active + .tns-slide-active {
  opacity: 1;
}

.gallery-item {
  text-align: center;

  img {
    display: block;
    width: 100%;
    margin-bottom: rem-calc(16);
  }
}

.tns-controls {
  text-align: center;
  outline: none;

  [data-controls='prev'], [data-controls='next'] {
    display: inline-block;
    text-indent: -9999px;
    -webkit-appearance: none;
    border: none;
    z-index: 2;
    transition: .4s all ease-in-out;
    cursor: pointer;
    width: rem-calc(12);
    height: rem-calc(12);
    background: none;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: rem-calc(16);
    opacity: 0.8;
    padding: rem-calc(8);

    &:hover, &:focus {
      opacity: 1;
    }
  }

  [data-controls='prev'] {
    left: 0;
    background-image: url('../img/dist/icons/arrow-left.svg');
  }

  [data-controls='next'] {
    right: 0;
    background-image: url('../img/dist/icons/arrow-right.svg');
  }
}