.hero {
  position: relative;
  min-height: 80vh;
  padding-top: rem-calc(240);
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: $white;
  padding-bottom: rem-calc(32);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $gray-400;
    mix-blend-mode: multiply;
    z-index: 2;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 3;

    h1, h2 {
      position: relative;
      z-index: -1;
      color: inherit;
      font-family: $script-font-family;
      color: $primary-400;
      line-height: 1;
      font-size: 8vw;

      @include breakpoint(medium) {
        opacity: 0.6;
      }

      @include breakpoint(large) {
        font-size: rem-calc(120);
      }
    }
    
    .button {
      margin-bottom: rem-calc(32);
    }
  }
}