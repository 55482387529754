ul {
  &.social {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      list-style-type: none;
      padding: rem-calc(4 16);

      img {
        display: block;
        height: rem-calc(16);
      }
    }
  }
}