.footer {
  text-align: center;
  padding: rem-calc(16 0 64);

  .logo {
    display: inline-block;
    width: rem-calc(260);
    margin-bottom: rem-calc(64);
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      list-style-type: none;

      a {
        display: inline-block;
        padding: rem-calc(4 32);
        text-transform: none;
        color: $primary-900;
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem-calc(13);
        font-weight: bold;
        letter-spacing: .25em;
      }
    }
  }
}
