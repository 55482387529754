.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: rem-calc(16 0);
  text-align: center;
  z-index: 10;

  .logo {
    display: inline-block;
    max-width: rem-calc(320);
  }
}
