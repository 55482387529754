body {
  @include breakpoint(medium, max) {
    font-size: rem-calc(14);
  }
}

.title {
  text-align: center;
  margin-bottom: rem-calc(64);

  h1, h2 {
    font-family: $script-font-family;
    color: $secondary;
    opacity: 0.6;
    line-height: 1;
    font-size: 8vw;

    @include breakpoint(medium) {
      opacity: 0.6;
    }

    @include breakpoint(large) {
      font-size: rem-calc(120);
    }
  }
}